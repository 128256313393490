<template>
  <div
    v-if="isShow"
    id="bottom-banner"
    tabindex="-1"
    class="fixed bottom-0 start-0 z-50 flex justify-between w-full p-4 border-t border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
  >
    <div class="flex items-center mx-auto">
      <p
        class="flex items-center text-base font-normal text-gray-500 dark:text-gray-400"
      >
        <span class="leading-7">
          Order from thousands of pharmacy products and get them delivered to your doorstep.
          <!-- <span class="text-viola-400 font-semibold leading-7">Pharmacy</span>
          and
          <span class="text-viola-400 font-semibold leading-7">Orders</span>
          always with you. -->
          <a
            href="https://link.healistic.net/SaGz/disqbnwy"
            class="ms-0 font-semibold leading-7 text-viola-400 md:ms-1 md:inline-flex dark:text-viola-400 underline"
          >
            Download our Healistic app
          </a>
        </span>
      </p>
    </div>
    <div class="flex items-center">
      <button
        data-dismiss-target="#bottom-banner"
        type="button"
        class="flex-shrink-0 inline-flex justify-center items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
        @click="closeBanner"
      >
        <XCircleIcon class="w-6 h-6" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { XCircleIcon } from "@heroicons/vue/24/outline";

const isShow = ref(true);

const closeBanner = () => {
  isShow.value = false;
};
</script>
