<template>
  <NuxtLayout>
    <header>
      <nav
        class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div class="flex flex-1">
          <div class="hidden lg:flex lg:gap-x-12">
            <NuxtLink
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              target="_blank"
              class="text-sm font-semibold leading-6 text-gray-900"
              >{{ item.name }}</NuxtLink
            >
          </div>
          <div class="flex lg:hidden">
            <button
              type="button"
              class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              @click="mobileMenuOpen = true"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <button @click="goToMainPage()">
          <span class="flex items-center justify-center gap-x-4">
            <img class="h-8 w-8" src="/icon.svg" alt="Healistic" />
            <span class="text-xl font-semibold text-viola-400">Healistic</span>
          </span>
        </button>
        <div class="flex flex-1 justify-end">
          <button
            v-if="useRoute().path !== '/'"
            class="text-sm font-semibold leading-6 text-gray-900"
            @click="goToMainPage()"
          >
            <ArrowLeftStartOnRectangleIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <TransitionRoot as="template" :show="mobileMenuOpen">
        <Dialog as="div" class="relative z-10" @close="mobileMenuOpen = false">
          <div class="fixed inset-0" />

          <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
              <div
                class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10"
              >
                <TransitionChild
                  as="template"
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enter-from="-translate-x-full"
                  enter-to="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leave-from="translate-x-0"
                  leave-to="-translate-x-full"
                >
                  <DialogPanel class="pointer-events-auto w-screen max-w-md">
                    <div
                      class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
                    >
                      <div class="px-4 sm:px-6">
                        <div class="flex items-start justify-between">
                          <DialogTitle
                            class="text-base font-semibold leading-6 text-gray-900"
                          >
                            Menu
                          </DialogTitle>
                          <div class="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 hover:outline-none hover:ring-2 hover:ring-viola-400 hover:ring-offset-2"
                              @click="mobileMenuOpen = false"
                            >
                              <span class="absolute -inset-2.5" />
                              <span class="sr-only">Close panel</span>
                              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <div class="mt-6 space-y-2">
                          <a
                            v-for="item in navigation"
                            :key="item.name"
                            :href="item.href"
                            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >{{ item.name }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </header>
    <AppBanner />
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/vue/24/outline";

import { CookieKeyEnum } from "~/enums";
import AppBanner from "~/components/features/appBanner.vue";

createAxiosInstance();
createUnauthenticatedAxiosInstance();

const navigation = [
  { name: "FAQs", href: "https://www.healistic.net/help-centre-1" },
  { name: "Contact us", href: "https://www.healistic.net/help-centre" },
];

const mobileMenuOpen = ref(false);

const goToMainPage = () => {
  useCookie(CookieKeyEnum.TOKEN).value = null;
  navigateTo("/");
};
</script>
