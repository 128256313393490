<template>
  <div class="h-lvh px-6 py-24 sm:py-32 lg:px-8 mt-14">
    <div class="text-center">
      <p class="text-base font-semibold text-viola-400">{{ title }}</p>
      <h1
        class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
      >
        {{ text }}
      </h1>
      <p class="mt-6 text-base leading-7 text-gray-600">
        Sorry, we couldn’t find the page you’re looking for.
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <nuxt-link
          to="/"
          class="rounded-md bg-viola-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-viola-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-viola-400"
          >Back to home</nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { error } = defineProps({
  error: {
    type: Object,
    required: true,
  },
});
console.log({ error });
const title =
  error.statusCode === 404
    ? "404 - Page not found"
    : `${error.statusCode} - Unexpected error`;
const text =
  error.statusCode === 404
    ? "The page you are looking for does not exist. Please check the entered URL and try again."
    : "An unexpected error occurred. Please try again later.";
</script>
