import { CookieKeyEnum } from "~/enums";

export default defineNuxtPlugin(() => {
  const userAuth = useCookie(CookieKeyEnum.TOKEN);
  const config = useRuntimeConfig();

  const $customFetch = $fetch.create({
    baseURL: config.public.API_URL + "/customer/v1",
    onRequest({ options }) {
      if (userAuth.value) {
        // Add Authorization header
        const prevHeaders = options.headers || {};
        options.headers = {
          ...prevHeaders,
          Authorization: `Bearer ${userAuth.value}`,
        };
      }
    },
    onResponseError({ response }) {
      if (response.status === 401) {
        useCookie(CookieKeyEnum.TOKEN).value = null;
        navigateTo("/");
      }
    },
  });

  return {
    provide: {
      customFetch: $customFetch,
    },
  };
});
